import FirstImage from "./images/1.jpg";
import SecondImage from "./images/2.jpg";
import ThirdImage from "./images/3.jpg";
import SeventhImage from "./images/7.jpg";
import EighthImage from "./images/8.jpg";
import NinthImage from "./images/9.webp";
import POneImage from "./images/p1.jpg";
import PTwoImage from "./images/p2.jpg";
import PThreeImage from "./images/p3.jpg";
import PFourImage from "./images/p4.jpg";

export const first62ImagePropts = [
  // charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving on a road in {Company}, {State Name}. The motorcoach has the text “Charter Bus Rental Company {Company}” clearly visible on the side in black font. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "charter-bus",
  },
  // 56-passenger-motorcoach
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving on a road in {Company}, {State Name}. The motorcoach has the text “Charter Bus Rental Company {Company}” clearly visible on the side in black font. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-motorcoach",
  },
  // 15-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "15-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "15-passenger-minibus-inside",
  },
  // 18-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "18-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "18-passenger-minibus-inside",
  },
  // 20-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "20-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "20-passenger-minibus-inside",
  },
  // 25-passenger-minibus
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "25-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "25-passenger-minibus-inside",
  },
  // 28-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "28-passenger-minibus-inside",
  },
  // 30-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "30-passenger-minibus-inside",
  },
  // 35-passenger-minibus
  {
    prompt:
      "A photograph of  a white modern GM40 Freightliner S2 shuttle bus in a photographic studio with white walls and even. The bus has a modern design with a sleek curved front and a prominent grille. It has a high roof, large windows, and a sturdy build.  The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus",
  },
  {
    prompt:
      "Photo of black minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-rental",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-interior",
  },
  {
    prompt:
      "Photo of grey, cloth/fabric minibus seats. It features rows of plush seats with chevron-patterned backrests. 14 seats total in 2 x 2 rows, one flat aisle in the middle like a real minibus. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "35-passenger-minibus-inside",
  },
  // 50-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "50-passenger-charter-bus-inside",
  },
  // 54-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "54-passenger-charter-bus-inside",
  },
  // 55-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "55-passenger-charter-bus-inside",
  },
  // 56-passenger-charter-bus
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in a photographic studio with white walls and even lighting. The motorcoach is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-rental",
  },
  {
    prompt:
      "Photo of grey motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-interior",
  },
  {
    prompt:
      "Photo of black motorcoach seats. It features rows of plush seats with chevron-patterned backrests. 40 seats total in 2 x 2 rows, one flat aisle in the middle like a real motorcoach. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "56-passenger-charter-bus-inside",
  },
  // sprinter-van-with-driver
  {
    prompt:
      "photo of a modern black Mercedes Sprinter Van in a photographic studio with white walls and even lighting. The van is parked on a smooth surface. The background is clean and free of distractions.  Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. It features 10 seats, rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real sprinter van. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-rental",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. It features 10 seats, rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real sprinter van. The floor appears to be dark colored wood.  The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-interior",
  },
  {
    prompt:
      "Photo of black Mercedes sprinter van seats. There are multiple plush, black cloth seats arranged in two rows, each equipped with a white headrest and a safety belt. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "sprinter-van-with-driver-inside",
  },
  // school-bus-rental
  {
    prompt:
      "photo of a modern yellow school bus in a photographic studio with white walls and even lighting. The bus is parked on a smooth surface. The background is clean and free of distractions. Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "school-bus-rental",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-rental",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-interior",
  },
  {
    prompt:
      "Photo of school bus. It features rows of plush seats in 2 x 2 rows, one flat aisle in the middle like a real school bus. The floor appears to be dark colored wood. The photo is a RAW photo shot with a DSLR camera using an 85mm lens.  A001_05231427_C001.braw",
    imageName: "school-bus-rental-inside",
  },
  // party-bus-rental
  {
    prompt:
      "A photograph of  a large white modern Champion Ford F550 Challenger Shuttle Bus in a photographic studio with white walls and even. The bus is parked on a smooth surface. The background is clean and free of distractions. The image has a low ceiling and is an award-winning composition. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "party-bus-rental",
  },
  // sprinter-limo-rental
  {
    prompt:
      "photo of a modern black Mercedes Sprinter Van in a photographic studio with white walls and even lighting. The van is parked on a smooth surface side ways. The background is clean and free of distractions.  Low ceiling... The photo is a RAW photo shot with a DSLR camera using an 85mm lens A001_05231427_C001.braw",
    imageName: "sprinter-limo-rental",
  },
  // corporate-bus-rental
  {
    prompt:
      "a happy corporate guy smiling at the camera while sitting in a modern motorcoach seat doing thumbs up. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "corporate-bus-rental",
  },
  // wedding-transportation
  {
    prompt:
      "wedding couple smiling at camera. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "wedding-transportation",
  },
  // sporting-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach parked in an empty parking lot in {Company}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "sporting-event-transportation",
  },
  // school-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach in {Company,  State}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "school-event-transportation",
  },
  // airport-shuttles
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving to a {State} airport.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "airport-shuttles",
  },
  // private-event-transportation
  {
    prompt:
      "A photograph of a large white modern, 2024 motorcoach driving around {Company, State}.. The photo is a RAW photo shot with a DSLR camera using an 85mm lens. A001_05231427_C001.braw",
    imageName: "private-event-transportation",
  },
];

export const tenImagesPropts = [
  // 1.jpg
  {
    imageIndex: "1.jpg",
    image: FirstImage,
    prompt: `The interior of a luxurious bus or vehicle. It features plush, black leather seats with a diamond-patterned design. The flooring appears to be made of wood, and there are red ambient light strips along the windows. The windows are covered with black blinds, and there's a television mounted on the back wall. The overall ambiance suggests comfort and modernity.`,
  },
  // 2.jpg
  {
    imageIndex: "2.jpg",
    image: SecondImage,
    prompt: `The interior of a luxurious vehicle, possibly a bus or a limousine. It features plush, diamond-patterned black leather seats arranged in a linear fashion. The seating area is illuminated with a soft blue glow, and there's a central console with a glass decanter and two glasses. The vehicle's windows are adorned with red shades, and there's a large screen displaying an image of a car. The floor is also illuminated in a similar blue hue, and there are several decorative elements, including red flowers and a small table with a vase.`,
  },
  // 3.jpg
  {
    imageIndex: "3.jpg",
    image: ThirdImage,
    prompt: `The interior of a luxurious vehicle, possibly a bus or a limousine. It features plush, diamond-patterned black leather seats, a central console with a television displaying an image of a vehicle, and ambient lighting in hues of pink and orange. The flooring appears to be made of a glossy material, and there's a small table with a bottle and two glasses on it. The overall ambiance suggests comfort and opulence.`,
  },
  // 7.jpg
  {
    imageIndex: "7.jpg",
    image: SeventhImage,
    prompt: `The interior of a luxurious vehicle, possibly a limousine or a high-end bus. The dominant colors are purple and black, with neon pink accents. The seating area is plush, featuring quilted leather seats in a diamond pattern. There's a large window on the left, covered with vertical blinds. Above the seating area, there's a television displaying a colorful scene, possibly from a concert or a show. On the right side, there's a counter with a sink and storage compartments. The floor has a gray, wood-like design, and the ceiling is adorned with multiple round lights, giving the space a modern and chic ambiance.`,
  },
  // 8.jpg
  {
    imageIndex: "8.jpg",
    image: EighthImage,
    prompt: `The interior of a luxurious vehicle, possibly a limousine or a high-end bus. The dominant colors are purple and black, with neon lights illuminating the space. The seating area is plush, featuring a long, curved bench with diamond-patterned upholstery. There's a bar area with a unique, curved design, housing multiple cups. The ceiling is adorned with LED lights, and there are large windows on one side, offering a glimpse of the outside environment. The overall ambiance exudes opulence and comfort.`,
  },
  // 9.webp
  {
    imageIndex: "9.webp",
    image: NinthImage,
    prompt: `The interior of a luxurious vehicle, possibly a limousine or a high-end car. The space is adorned with neon lights in shades of blue and purple, illuminating the interior. The seating area is plush, featuring black leather seats with a quilted pattern. There's a large screen mounted on the back wall displaying an image of a person in a futuristic setting. The floor has a reflective, possibly metallic, finish in a shade of purple. On the left side, there's a bar area with multiple compartments, possibly for storing beverages. The overall ambiance of the vehicle exudes opulence and modernity.`,
  },
  // p1.jpg
  {
    imageIndex: "p1.jpg",
    image: POneImage,
    prompt: `The interior of a luxurious vehicle, possibly a limousine or a high-end car. The dominant colors are shades of blue and purple, with neon-like lighting accentuating the design. The seating area is plush, featuring dark leather with a quilted pattern. The windows are adorned with blue LED strips, and the ceiling has a series of rectangular lights. The overall ambiance exudes sophistication and modernity.`,
  },
  // p2.jpg
  {
    imageIndex: "p2.jpg",
    image: PTwoImage,
    prompt: `The interior of a luxurious bus or limousine. It features plush black leather seats, each with a distinct diamond-patterned design. The seats are arranged in a two-row, three-column fashion, providing ample legroom. On the right side, there's a bar area with a shiny black surface, holding several glasses and a bottle. Large windows on both sides allow for natural light to flood in, revealing a green outdoor environment with trees. The overall ambiance suggests comfort and elegance, suitable for upscale travel or events.`,
  },
  // p3.jpg
  {
    imageIndex: "p3.jpg",
    image: PThreeImage,
    prompt: `The interior of a luxurious vehicle, possibly a bus or a limousine. The ceiling is adorned with vibrant neon lights in shades of blue, green, and red. There are two large, plush, black leather seats with a diamond-patterned design. Above the seats, there's a television displaying a scenic view of trees during autumn. The windows are covered with black blinds, and the floor appears to be made of polished wood. The overall ambiance of the interior is modern, sleek, and opulent.`,
  },
  // p4.jpg
  {
    imageIndex: "p4.jpg",
    image: PFourImage,
    prompt: `The interior of a modern bus or shuttle. It features plush, leather seats in a dark hue, adorned with a diamond-patterned design. The ceiling is equipped with neon green lights, providing a vibrant ambiance. Large windows, tinted in black, are evenly spaced along the sides, allowing passengers to view the outside. The floor is wooden, and there are green LED lights embedded beneath the seats. The overall design suggests a luxurious and comfortable travel experience.`,
  },
];

export const last6ImageArray = [
  // party-bus-rental-rental
  {
    isRemixNeeded: true,
    imageName: "party-bus-rental-rental",
  },
  // party-bus-rental-interior
  {
    isRemixNeeded: true,
    imageName: "party-bus-rental-interior",
  },
  // party-bus-rental-inside
  {
    isRemixNeeded: true,
    imageName: "party-bus-rental-inside",
  },
  // sprinter-limo-rental-rental
  {
    isRemixNeeded: true,
    imageName: "sprinter-limo-rental-rental",
  },
  // sprinter-limo-rental-interior
  {
    isRemixNeeded: true,
    imageName: "sprinter-limo-rental-interior",
  },
  // sprinter-limo-rental-inside
  {
    isRemixNeeded: true,
    imageName: "sprinter-limo-rental-inside",
  },
];

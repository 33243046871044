import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Ideogram from "./Ideogram";
import ImageProcessor from "./ImageProcessor";
import RichTextEditor from "./Draft";
import Super68ImageGenerator from "./Super68ImageGenerator";
import ReviewImage from "./ReviewImage";
import ImageGenerator from "./ImageGenerator";
import ImageGeneratorNewCity from "./ImageGeneratorNewCity";
import Sitemap from "./Sitemap";
import Login from "./Login";
import PrivateRoute from "./routes/PrivateRoute";
import Logout from "./Logout";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Login route */}
          <Route path="/login" element={<Login />} />

          {/* Logout route */}
          <Route path="/logout" element={<Logout />} />

          {/* Home route */}
          <Route path="/" element={<RichTextEditor />} />

          {/* Image processor route */}
          {/* <Route path="/magic" element={<ImageProcessor />} /> */}

          <Route
            path="/blast"
            element={<PrivateRoute element={<Ideogram />} />}
          />

          {/* <Route
            path="/super68"
            element={<PrivateRoute element={<Super68ImageGenerator />} />}
          /> */}

          <Route
            path="/review"
            element={<PrivateRoute element={<ReviewImage />} />}
          />

          <Route
            path="/secret"
            element={<PrivateRoute element={<ImageGeneratorNewCity />} />}
          />

          {/* <Route
            path="/new-secret"
            element={<PrivateRoute element={<ImageGeneratorNewCity />} />}
          /> */}

          <Route
            path="/old-secret"
            element={<PrivateRoute element={<ImageGenerator />} />}
          />

          <Route path="/sitemap" element={<Sitemap />} />

          {/* Catch all non-existing routes and redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
